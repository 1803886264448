.logo {
    &__img {
        max-width: 138px;
    }
    
}

@media screen and (max-width: 1200px) {
    .logo {
        &__img {
            max-width: 100px;
        }
        
    }
}

@media screen and (max-width: 768px) {
    .logo {
        &__img {
            margin-left: 10px;
        }
        
    }
}