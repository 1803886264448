.menu {
    display: flex;
    // margin-right: 20px!important;
    justify-content: space-between;
    align-items: center;

    &_mobile {
        margin: 0 10px 0;
        flex-direction: column;
        text-align: left;
        align-items: baseline;

        &-visible {
            align-items: center;
            margin-top: 10px;
        }
    }
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 2px solid transparent!important;
}

.top-menu {
    padding-bottom: 3px;
    font-size: 16px!important;
    font-weight: 500;
    text-transform: uppercase;
    border-bottom: solid 1px rgba(0, 0, 0, 0.06);
}

.ant-menu-horizontal {
    border-bottom: none!important;
}

.ant-menu-item-selected {
    color: #621D0E!important;
    border-bottom: 2px solid #621D0E!important;
}

.item {
    margin-top: 6px!important;
    font-size: 16px!important;
    font-weight: 500!important;
    color: #000!important;

    i {
        margin-right: 7px;
        font-size: 20px;
    }

    &:hover {
        border-bottom: 2px solid #621D0E!important;
    }

    &__link {
        color: #000!important;
        &:hover {
            color: #621D0E!important;
        }

        &_more {
            margin-top: 5px!important;
            font-size: 15px!important;
        }

        &_mobile {
            margin-bottom: 5px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .ant-menu-item {
        padding: 0!important;
    }

    .top-menu {
        padding-bottom: 2px;
        font-size: 12px!important;
    }
    
    .item {
        font-size: 12px!important;
        padding: 0 10px!important;
        i {
            margin-right: 3px;
            font-size: 16px;
        }

        &__link {
            &_more {
                margin-top: 0!important;
                i {
                    font-size: 30px!important;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .top-menu {
        margin-top: 0px;
    }

    .item {
        margin-left: 10px;

        &:hover {
            border-bottom: transparent !important;
        }
    }
}

@media screen and (max-width: 320px) {
    .top-menu {
        display: none;
    }
}