.filter {
    margin: 20px 0;

    &__link {
        color: #fff;
        font-size: 15px;
        padding: 8px 12px;
        background-color: #656565;
        border-radius: 7px;
        display: block;
        margin-bottom: 5px;

        &:hover {
            color: #fff;
            background-color: #621D0E;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 1200px) {
    .filter {
        &__link {
            font-size: 12px;
            padding: 6px 8px;
            border-radius: 5px;
        }
    }
}

@media screen and (max-width: 768px) {
    .filter {
        justify-content: left!important;
        &__link {
            font-size: 14px;
            padding: 8px 12px;
            margin-bottom: 7px;
            margin-left: 8px;
        }
    }
}