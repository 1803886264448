.faqpage {
    &__header {
        font-size: 20px;
        font-weight: 600;
        color: #621D0E;
    }

    &__text {
        font-size: 15px;
        font-weight: 300;
        line-height: 1.4em;
    }

    &__title {
        font-size: 18px;
        color: #621D0E;
        font-weight: 500;
    }

    &__item {
        &_margin {
            margin-bottom: 40px!important;
            margin-top: 20px!important;
        }
    }

    &__items {
        width: 100%;
        background-color: #fff!important;
        border: 0!important;
    }
}

@media screen and (max-width: 768px) {
    .faqpage {
        padding: 0 15px;
    }
}

@media screen and (max-width: 425px) {
    
}