.cart {
    margin-top: 20px;
    font-size: 15px!important;
    // padding: 8px 17px!important;

    &__title {
        text-align: center;
    }

    &__items {
        margin-top: 20px;
        text-align: center;
    }

    &__item {
        margin-bottom: 10px;
    }

    &__product {
        cursor: pointer;
        color: #621D0E;
        border-bottom: 1px dashed #621D0E;
        width: 218px;
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
        position: relative;
    }

    &__popover {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2003;
    }

    &__price {
        font-weight: 600;

        i {
            font-size: 12px;
        }

        &_lg {
            font-size: 16px;

            i {
                font-size: 13px;
            }
        }
    }

    &__prod-count {
        color: #717171;
        text-align: left;
    }

    &__sum-text {
        font-weight: 600;
        // text-transform: uppercase;
        font-size: 16px;
    }

    &__summary {
        margin-top: 4px;
        margin-bottom: 20px;
    }

    &__offer {
        margin-top: 10px;
    }

    &__count-btn {
        padding: 0 10px!important;
        font-size: 18px;
        font-weight: 600;

        &_sub {
            margin-right: 10px;
        }

        &_add {
            margin-left: 10px;
            font-size: 17px;
            padding: 0 9px!important;
        }
    }
}

.order {
    &-contact {
        margin-top: 20px;
    }

    &-form {
        margin-top: 40px;

        &__add-marks {
            margin-bottom: 15px;
        }
    
        &__item {
            margin-bottom: 15px;
        }
    
        &__title {
            text-transform: none;
            margin-bottom: 15px;
            font-weight: 500;
            font-size: 18px;
        }

        &__error {
            color: red;
            font-size: 12px;
        }
    
        &__label {
            font-size: 14px;
            font-weight: 500;
            color: #717171;
        }
    
        &__require {
            font-size: 18px;
            color: #621D0E;
            font-weight: 500;
            margin-left: 3px;
        }
    
        &__input {
            display: block;
            width: 100%;
            border: 0;
            color: #000;
            font-size: 16px;
            border-bottom: 1px solid #575757;
            padding: 3px 0;
    
            &::placeholder {
                color: #717171!important;
            }
    
            &_short {
                width: 120px;
            }

            &_short1 {
                width: 60px;
            }

            &_mobile {
                width: 120px;
            }
        }

        &__select {
            display: block;
            width: 100%;
            border: 0;
            color: #000;
            font-size: 16px;
            border-bottom: 1px solid #575757;
            padding: 3px 0;
        }

        &__checkbox {

            margin-bottom: 20px!important;
            display: block;

            &-text {
                font-size: 16px!important;
                font-weight: 300;
                color: #000;
            }
        }

        &__time {
            width: 100%;
            border: 0!important;
            border-bottom: 1px solid #575757!important;
            
            input::placeholder {
                color: #717171!important;
            }
        }

        &__textarea {
            border: 0!important;
            padding: 0!important;
            border-bottom: 1px solid #575757!important;
            resize: none!important;

            ::placeholder {
                color: #717171!important;
            }
        }

        &__text {
            color: #575757;
            margin: 20px 0;
        }

        &__button {
            width: 100%;
            font-weight: 500;
            letter-spacing: 0.08em;
            cursor: pointer;
        }

        &__slider {
            width: 70%;
        }

        &__marks {
            color: #621D0E;
            font-weight: 60;
        }
    }

    &-food-dev, &-time, &-pay {
        margin-top: 40px;
    }
}

textarea::placeholder {
    color: #717171!important;
}

@media screen and (max-width: 1200px) {
    .cart {
        margin-top: 10px;
        font-size: 13px!important;
        
        &__product {
            width: 148px;
        }
    
        &__price {
            font-weight: 500;
    
            i {
                font-size: 10px;
            }
        }
    
        &__count-btn {
            padding: 0 10px!important;
            font-size: 15px;
        }
    }
    
    .order {
        &-contact {
            margin-top: 20px;
        }
    
        &-form {
            &__item {
                margin-bottom: 10px;
            }
        
            &__title {
                font-size: 16px;
            }
        
            &__label {
                font-size: 14px;
            }
        
            &__require {
                font-size: 16px;
            }
        
            &__input {
                font-size: 14px;
                
                &_short {
                    width: 80px;
                }

                &_mobile {
                    width: 150px;
                }
            }
    
            &__select {
                font-size: 14px;
                
            }
    
            &__checkbox {
    
                &-text {
                    font-size: 13px!important;
                }
            }
    
            &__text {
                font-size: 12px;
            }
    
            &__button {
                width: 100%;
                font-weight: 500;
                letter-spacing: 0.08em;
                cursor: pointer;
            }
        }
    
        &-food-dev, &-time, &-pay {
            margin-top: 40px;
        }
    }
}