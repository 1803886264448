.aboutpage {
    &__header {
        font-size: 20px;
        font-weight: 600;
        color: #621D0E;
    }

    &__text {
        font-size: 15px;
        font-weight: 300;
        line-height: 1.4em;
        margin: 15px 0;

        &_margin {
            margin-bottom: 40px;
        }
    }

    &__important {
        font-size: 18px;
        font-weight: 300;
        color: #621D0E;
        margin-top: 30px;
    }

    &__contact-header {
        font-size: 18px;
        color: #621D0E;
        margin-bottom: 20px;
    }

    &__location {
        margin-right: 80px;
    }

    &__contacts {
        &_margin {
            margin-bottom: 40px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .aboutpage {
        &__header {
            font-size: 18px;
            line-height: 1.5em;
        }

        &__location {
            margin-right: 20px;
        }
    }
}

@media screen and (max-width: 768px) {
    .aboutpage {
        padding: 0 15px;
    }
}

@media screen and (max-width: 425px) {
    .aboutpage {
        &__contacts {
            flex-direction: column!important;
        }

        &__location {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
}