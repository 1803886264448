.ant-layout {
    background: white;
}

.ant-layout-header {
    background-color: transparent;
    height: 0;
    padding: 0;
    line-height: 1em;
}

.ant-drawer-body {
    padding: 15px 35px!important;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color: transparent;
    border-right-width: 1px !important;
    -webkit-box-shadow: 0 0 0 2px transparent;
    box-shadow: 0 0 0 2px transparent;
}

.ant-input-search-icon {
    color: rgba(98, 29, 14, 0.55)!important;

    &:hover {
        color: #621D0E!important;
    }
}

.ant-select-selector {
    border: 0!important;
}

.ant-select-focused {
    outline: none!important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #fff;
    background-color: rgba(98, 29, 14, 0.55);
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: #fff;
    background-color: rgba(98, 29, 14, 0.55);
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    color: #fff;
    background: rgba(98, 29, 14, 0.55);
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    color: #fff;
    background: rgba(98, 29, 14, 0.55);
}

.ant-checkbox-checked {
    background-color: #621D0E;

    &::after {
        border: 1px solid #621D0E;
        background-color: #621D0E;
        border-radius: 2px;
        visibility: hidden;
        -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
        animation: antCheckboxEffect 0.36s ease-in-out;
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        content: '';
    }
}

.anticon svg {
    fill: #621D0E;
}

.ant-picker-now-btn {
    color: #621D0E;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #621D0E;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #621D0E;
    border-color: #621D0E;
}

.ant-btn-primary {
    background-color: #621D0E;
    border-color: #621D0E;

    &:hover{
        color: #621D0E;
        background-color: #fff;
        border-color: #621D0E;
    }

    &:active {
        background-color: #621D0E;
        border-color: #621D0E;
    }
}

.ant-btn {

    &:hover{
        color: #621D0E;
        background-color: #fff;
        border-color: #621D0E;
    }

    &:active {
        color: #621D0E;
        background-color: #fff;
        border-color: #621D0E;
    }
}

.ant-picker.ant-picker-borderless{
    border-color: #575757!important;
}

.ant-radio-checked .ant-radio-inner {
    border-color: #621D0E;
}

.ant-radio-inner::after {
    background-color: #621D0E;
}

.ant-radio-checked::after {
    border: 1px solid #621D0E;
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: #621D0E;
}

.ant-drawer-top .ant-drawer-content-wrapper {
    width: 1234px;
    left: 329px;
}

.ant-bage-cart .ant-badge-count {
    top: -20px;
    left: -10px;
    background-color: #621D0E;
    color: #fff;
    box-shadow: 0 0 0 1px #fff inset;
}

.ant-badge-count {
    padding-top: 2px;
    font-weight: 600;
}

.ant-radio-button-wrapper {
    border-radius: 10px!important;
    width: 128px!important;
    text-align: center;
    border-color: transparent!important;
    background-color: transparent!important;

    &:hover {
        color: #A68582!important;
    }
}

.ant-radio-button-wrapper::before {
    background-color: transparent!important;
    
}

.ant-radio-button-wrapper-checked {
    background-color: #A68582!important;
    color: #fff!important;

    &:hover {
        color: #fff!important;
    }
}

.ant-radio-group {
    // background-color: #FAFAFA!important;
    border-radius: 10px!important;
}

.ant-spin-dot-item {
    background-color: #621D0E;
}

.ant-collapse-item, .ant-collapse-content {
    border: 0!important;
}

.ant-popover {
    z-index: 3000;
}

.ant-modal-mask {
    z-index: 4000!important;
}

.ant-modal-wrap, .ant-select-dropdown, .ant-picker-dropdown, .ant-notification {
    z-index: 4001!important;
}

.ant-tabs-nav .ant-tabs-tab {
    margin-right: 15px;
}

.ant-tabs-nav .ant-tabs-tab-active {
    color: #621D0E;
    text-transform: uppercase;
}

.ant-tabs-tab {
    text-transform: uppercase;
}

.ant-tabs-nav .ant-tabs-tab:active {
    color: #621D0E;
    text-transform: uppercase;
}

.ant-tabs-nav .ant-tabs-tab:hover {
    color: #621D0E;
}

.ant-tabs-ink-bar {
    background-color: transparent;
}

.ant-bage-cart_mobile {
    top: 10px;
    left: 10px;
}

.ant-slider-handle.ant-tooltip-open {
    border-color: #621D0E;
}

.ant-slider-track {
    background-color: #621D0E;
}

.ant-slider:hover .ant-slider-track {
    background-color: #621D0E;
}

@media screen and (max-width: 1440px) {
    .ant-drawer-top .ant-drawer-content-wrapper {
        width: 755px;
    }
}

@media screen and (max-width: 1200px) {
    .ant-drawer-top .ant-drawer-content-wrapper {
        width: 585px;
        left: 175px;
    }

    .ant-drawer-body {
        padding: 15px 10px !important;
    }
}

@media screen and (max-width: 768px) {
    .ant-drawer-top .ant-drawer-content-wrapper {
        width: 358px;
        left: 175px;
    }
}

@media screen and (max-width: 425px) {
    .ant-drawer-top .ant-drawer-content-wrapper {
        width: 100%;
        left: 0px;
    }
}