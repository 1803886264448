.slogan {
    font-size: 24px;
    color: #621D0E;
    font-weight: 600;
    text-transform: uppercase;
    display: block;
}

@media screen and (max-width: 1200px) {
    .slogan {
        font-size: 16px;
        font-weight: 500;
    }
}

@media screen and (max-width: 768px) {
    .slogan {
        display: none;
    }
}