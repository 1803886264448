$height: 535px;

.bonuses {
    margin: 80px 0;
    height: $height;
    background: #fff url('../../assets/img/qr.png') repeat -250px center / cover!important;
}

.bonus {
    height: $height;
    padding: 60px 0 60px 80px;
    background-color: rgba(0,0,0,0.55);

    &__offer {
        margin-left: auto;
    }

    &__title {
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0.06em;
        color: #fff;
        background-color: #FAC100;
        display: inline-block;
        padding: 5px 7px;
    }

    &__subtitle {
        font-size: 15px;
        font-weight: 500;
        color: #fff;
        letter-spacing: 0.03em;
        margin: 30px 0;
    }

    &__texts {
        color: #fff;
        font-size: 15px;
    }

    &__text {
        margin-top: 20px;
    }

    &__icon {
        max-width: 260px;
    }

    &__icons {
        margin-top: 20px;
    }
}

@media screen and (max-width: 1200px) {
    .bonuses {
        margin: 40px 0;
    }
    .bonus {
        &__title {
            font-size: 18px;
            font-weight: 500;
        }
    
        &__icon {
            max-width: 220px;
        }
    }
}

@media screen and (max-width: 768px) {
    .bonuses {
        margin: 20px 0;
    }
    .bonus {
        &__title {
            font-size: 16px;
        }
    
        &__icon {
            max-width: 200px;
        }

        &__text {
            margin-top: 10px;
        }

        &__subtitle {
            margin: 20px 0;
        }
    }
}

@media screen and (max-width: 425px) {
    .bonuses {
        margin: 20px 0;
    }

    .bonus {
        padding: 40px 10px;
    }
}

@media screen and (max-width: 320px) {
    .bonus {
        padding: 20px 10px;
        height: 625px;
    }
    .bonuses {
        height: 625px;
    }
}