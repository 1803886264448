.goods {
    padding: 0;
}

.good {
    width: 300px;
    height: 480px;
    border: 1px solid #fff;
    box-shadow: 0 3px 8px rgba(0,0,0,0.38);
    border-radius: 10px;
    margin: 10px 10px!important;
    flex-direction: column;
    padding: 20px 20px!important;
    // position: relative;
    display: block;

    &__modal {
        width: 800px!important;

        &-img {
            max-width: 750px;
            max-height: 700px;
        }

        &-text {
            margin-top: 15px;
            color: #000;
            font-weight: 500;
            font-size: 15px;
        }
    }

    &__new {
        position: absolute;
        top: -22px;
        left: -19px;
    }

    &_vegan {
        position: absolute;
        top: -36px;
        right: -19px;
        color: #00960C;
        font-size: 70px;
    }

    &__img {
        max-width: 250px;
        max-height: 220px;

        &-container {
            height: 220px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__add-info {
        width: 100%;
        margin-top: 10px;
    }

    &__stars, &__delivery {
        background-color: #FAC100;
        border-radius: 15px;
        padding: 0 7px;
        color: #fff;
        font-size: 14px;
    }
    &__delivery-time {
        margin-left: 6px;
    }

    &__main {
        margin-top: 15px!important;
    }

    &__title {
        text-transform: none;
        font-size: 18px;

        i {
            margin-right: 7px;
        }
    }

    &__text {
        font-size: 12px;
        color: #939393;
        height: 79px;
    }

    &__offer {
        width: 100%;
    }

    &__weight {
        font-size: 14px;
        color: #939393;
    }

    &__order-one-click {
        font-size: 15px;
        color: #621D0E;
        border-bottom: 1px dashed #621D0E;

        &:hover {
            color: #621D0E;
            border-bottom: 1px dashed transparent;
        }
    }

    &__main-offer {
        width: 100%;
        margin-top: 30px;
    }

    &__price {
        font-size: 19px;
        color: #621D0E;
        font-weight: 600;

        i {
            font-size: 16px;
        }
    }

    &__order-btn {
        font-size: 15px!important;
        text-transform: none!important;
        padding: 10px 20px!important;
        cursor: pointer;

        i {
            margin-right: 5px;
            font-size: 16px;
        }
    }

    &:hover {
        border: 1px solid #621D0E;
        box-shadow: 0 3px 8px #621D0E;
    }
}

.ant-card-body {
    padding: 0!important;
}

@media screen and (max-width: 1366px) {
    .good {
        width: 270px;
        height: 475px;
        padding: 15px 15px!important;
        margin: 0!important;
        margin-bottom: 10px!important;
        
        &__new {
            top: -22px;
            left: -19px;
        }
    
        &_vegan {
            top: -36px;
            right: -19px;
            font-size: 70px;
        }
    
        &__title {
            font-size: 14px;
            font-weight: 500;
    
            i {
                margin-right: 7px;
            }
        }
    
        &__text {
            font-size: 12px;
            height: 85px;
        }

        &__modal {
            width: 700px!important;
    
            &-img {
                max-width: 650px;
                max-height: 600px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .goods {
        padding-top: 10px;
    }
}

@media screen and (max-width: 768px) {
    .good {
        width: 328px;
        height: 480px;
        padding: 20px 20px!important;
        margin: 15px 15px!important;
        
        &__new {
            top: -22px;
            left: -19px;
        }
    
        &_vegan {
            top: -36px;
            right: -19px;
            font-size: 70px;
        }
    
        &__title {
            font-size: 17px;
            font-weight: 600;
        }
    }
}

@media screen and (max-width: 425px) {
    .good {
        &__modal {
            width: 400px!important;
    
            &-img {
                max-width: 350px;
                max-height: 300px;
            }
        }
    }
}