.account {
    
    &__sign {
        font-size: 40px;
        color: #621D0E;
        display: block;

        &:hover {
            color: #000;
        }
    }

    &__cart {
        margin-left: 15px;
        font-size: 44px;
        color: #621D0E;
        display: block;
        cursor: pointer;

        &:hover {
            color: #000;
        }
    }
}

@media screen and (max-width: 1200px) {
    .account {
        &__sign {
            margin-right: 15px;
            font-size: 30px;
        }
    
        &__cart {
            font-size: 32px;
        }
    }
}

@media screen and (max-width: 425px) {
    .account {
        margin: 20px 20px 0;
    }
}