@import "~reset-css";
@import "~antd/dist/antd.css";
@import "./antd_recall.scss";

body, 
html,
#root,
.App {
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    color: #000;
    font-size: 14px;
    font-weight: 300;
}

html {
    --antd-wave-shadow-color: #621D0E!important;
}

body {
    padding: 0;
    margin: 0;
    letter-spacing: 0.02em;
}

p {
    margin: 0;
    line-height: 1.4em;
}

ul, li {
    display: block;
    padding: 0;
    margin: 0;
}

a, input, button, div {
	transition: all 0.5s ease;
}

a:hover {
    text-decoration: none;
}

div, p, form, input, textarea, a, span, button {
	box-sizing: border-box;
}

input, input:hover, input:focus, input:active, button, button:hover, button:focus, button:active, textarea:focus, textarea:active {
	outline: none!important;
}

h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    font-weight: 600;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 26px;
}

h3 {
    font-size: 20px;
}

h4 {
    font-size: 20px;
}

h4 {
    font-size: 18px;
}

.header {
    padding: 20px 0;
    box-shadow: 0 3px 6px rgba(0,0,0,0.24);
    background-color: #FCFCFC;
    z-index: 1;

    &-fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        width: 100%;
    }
}

.main-content {
    margin-top: 230px;
}

.footer {
    background-color: #000;
    padding: 20px 0;

    &__phone_white {
        color: #fff;
        font-size: 24px;
        font-weight: 600;

        &:hover {
            color: #621D0E;
        }
    }

    &__rights {
        display: block;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.04em;
        padding: 10px 0;
    }
}

.rights {
    background-color: #621D0E;
    text-align: center;
}

.action_btn {
    background-color: #621D0E;
    border: 1px solid #621D0E;
    padding: 15px 20px;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    border-radius: 7px;
    box-shadow: 3px 3px 8px rgba(0,0,0,0.38);

    &:hover {
        background-color: #fff;
        border: 1px solid #621D0E;
        color: #621D0E;
    }

    &:disabled {
        background-color: #ccc!important;
        border: 1px solid #717171!important;
        color: #fff;
        cursor: not-allowed;
    }

}

.cart_btn {
    background-color: #fff;
    border: 1px solid #621D0E;
    padding: 6px 20px;
    font-size: 16px;
    color: #621D0E;
    text-align: center;
    border-radius: 7px;
    box-shadow: 3px 3px 8px rgba(0,0,0,0.38);
    width: 237px;

    &:hover {
        background-color: #621D0E;
        border: 1px solid #621D0E;
        color: #fff;
        cursor: pointer;
    }

}

.show-more_btn {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #fff;
    background-color: #FAC100;
    border: 2px solid #FAC100;
    border-radius: 7px;
    padding: 10px 0;
    cursor: pointer;

    &:hover {
        color: #FAC100;
        background-color: #fff;
        border: 2px solid #FAC100;
    }
}

::placeholder {
    color: #621D0E!important;
    opacity: 0.55!important;
}
  
:-ms-input-placeholder {
    color: #621D0E!important;
}
  
::-ms-input-placeholder {
    color: #621D0E!important;
}

::selection {
    color: #fff;
    background: #621D0E!important;
}

@media screen and (max-width: 1200px) {

    .action_btn {
        padding: 12px 12px;
        font-size: 14px;
    }

    .footer {
    
        &__phone_white {
            font-size: 18px;
        }
    
        &__rights {
            font-size: 16px;
        }
    }

    .main-content {
        margin-top: 200px;
    }

    .cart_btn {
        padding: 6px 20px;
        font-size: 15px;
        width: 195px;
    }
}

@media screen and (max-width: 768px) {
    .footer {
    
        &__phone_white {
            font-size: 16px;
        }
    
        &__rights {
            font-size: 12px;
        }
    }
}

@media screen and (max-width: 576px) {
    /* ------ ACTION ------ */
    .content {
        padding-top: 20px;
    }

    .main-content {
        margin-top: 235px;
    }
}

@media screen and (max-width: 425px) {
    .footer {

        &__root {
            flex-direction: column;   
        }

        &__phone_white {
            display: inline-block;
            margin-top: 10px;
        }
    
        &__rights {
            font-weight: 300;
        }
    }

    .main-content {
        margin-top: 250px;
    }
}

@media screen and (max-width: 320px) {
    .header {
        &__root {
            justify-content: center;
        }
    }

    .footer {
        &__root {
            justify-content: center;
        }
    }
}