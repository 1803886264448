.meals {
    background-color: #FCFCFC;
    box-shadow: 0 0 6px rgba(0,0,0,0.24);
    padding: 30px 0;
}

.meal {
    &__link {
        font-size: 15px;
        color: #656565;
        font-weight: 600;
        display: block;
        text-align: center;

        i {
            font-size: 60px;
        }

        &:hover {
            color: #621D0E;
        }
    }

    &__icon {
        height: 60px;
        max-width: 100%;
    }

    &__text {
        display: block;
        text-align: center;
        margin-top: 7px;

        &_mt {
            margin-top: 1px;
        }
    }
}

@media screen and (max-width: 425px) {
    .meals {
        padding: 10px 0;
    }

    .meal {
        margin-left: 10px;
        &__link {
            margin-right: 20px;
            font-size: 13px;
            font-weight: 300;
            margin-top: 10px;

            i {
                font-size: 30px;
            }
        }

        &__icon {
            height: 30px;
            max-width: 100%;
        }
    }
}