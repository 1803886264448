.delivery {
	&__header {
		font-size: 20px;
		font-weight: 600;
		color: #621d0e;

		&_margin {
			margin-top: 40px;
			font-size: 16px;
		}
	}

	&__img {
		margin-top: 30px;
		max-width: 100%;
	}

	&__item {
		display: flex;
		align-items: center;
		margin-right: 50px;

		i {
			color: #621d0e;
			font-size: 32px;
		}
	}

	&__text {
		font-size: 14px;
		font-weight: 400;
		width: 330px;
		line-height: 1.2em;
		margin-left: 10px;
	}

	&__city {
		display: flex;
		justify-content: start;
		margin-top: 20px;
	}

	&__precity {
		margin-top: 30px;
		width: 430px;
		font-size: 15px;
		font-weight: 400;
		line-height: 1.5em;

		&-item {
			&::before {
				content: '';
				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				margin-right: 7px;
				background-color: #621d0e;
			}
		}
	}

	&__footer {
		font-size: 16px;
		font-weight: 600;
		color: #621d0e;
		margin: 20px 0 40px;
	}
}

@media screen and (max-width: 1024px) {
	.delivery {
		&__item {
			margin-right: 20px;
			display: flex;
			align-items: flex-start;

			i {
				font-size: 22px;
			}
		}

		&__text {
			font-size: 13px;
			font-weight: 300;
			width: 200px;
			margin-left: 10px;
		}

		&__city {
			display: flex;
			justify-content: start;
			align-items: flex-start;
		}

		&__precity {
			margin-top: 20px;
			width: 230px;
			font-size: 13px;
		}

		&__footer {
			font-size: 14px;
			font-weight: 500;
		}
	}
}

@media screen and (max-width: 768px) {
	.delivery {
		padding: 0 10px;
	}
}

@media screen and (max-width: 425px) {
	.delivery {
		padding: 0 10px;
		&__header {
			font-size: 18px;
			font-weight: 600;
			line-height: 1.5em;

			&_margin {
				margin-top: 20px;
				font-size: 14px;
			}
		}

		&__img {
			margin-top: 10px;
			max-width: 100%;
		}

		&__item {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin-bottom: 15px;
			width: 100%;

			i {
				font-size: 22px;
			}
		}

		&__text {
			font-size: 15px;
			font-weight: 400;
			width: 345px;
			max-width: 100%;
			line-height: 1.2em;
			margin-left: 20px;
		}

		&__city {
			display: flex;
			justify-content: start;
			flex-direction: column;
		}

		&__precity {
			width: 190px;
		}

		&__footer {
			font-size: 14px;
			font-weight: 500;
			color: #621d0e;
			margin-bottom: 0px;
		}
	}
}
