.account {

    &__cards {
        margin: 20px 0;

        &_add {
            width: 1200px;
        }
    }

    &__header {
        font-size: 20px;
        font-weight: 600;
        color: #621D0E;

        &_info {
            font-size: 18px;
            font-weight: 300;
            color: #000;
            text-transform: none;
        }
    }

    &__infos {
        margin-top: 15px;
    }

    &__card {
        box-shadow: 0 3px 8px rgba(0,0,0,0.38);
        padding: 30px 35px;
        width: 478px;
    }

    &__title {
        font-size: 18px;
        font-weight: 600;
        text-transform: none;
        margin-bottom: 25px;
    }

    &__value {
        font-size: 16px;
        color: #575757;

        &_strong {
            font-size: 18px;
            font-weight: 500;
            color: #621D0E;
        }

        &_edited {
            color: #621D0E;
            border-bottom: 1px dotted #621D0E;
            padding-bottom: 3px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__button {
        border: 1px solid transparent;
        border-radius: 5px;

        &_accept {
            color: green;
            &:hover {
                border: 1px solid green;
                cursor: pointer;
            }
        }

        &_close {
            color: red;
            &:hover {
                border: 1px solid red;
                cursor: pointer;
            }
        }
    }

    &__input {
        width: 220px;
        border: 0;
        color: #000;
        font-size: 16px;
        border-bottom: 1px solid #575757;
        padding: 3px 0;
    }
}

.order {
    &__history {
        margin-top: 40px;
        width: 100%;
    }

    &__items {
        width: 100%;
        background-color: #fff!important;
        border: 0!important;
    }

    &__title {
        font-size: 18px;
        color: #621D0E;
        font-weight: 500;

        i {
            margin-left: 2px;
        }
    }

    &__item {
        &-text {
            font-size: 16px;
            color: #575757;
            width: 50px;
            text-align: right;

            &_name {
                width: 400px;
                text-align: left;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .account {        
    
        &__card {
            padding: 20px 25px;
            width: 438px;
        }
    }
}

@media screen and (max-width: 768px) {
    .account {        
        padding: 0 15px;

        &__card {
            width: 100%;
            margin-bottom: 15px;
        }
    }

    .order {
        &__history {
            margin-top: 0;
        }
    }
}

@media screen and (max-width: 425px) {
    .account {
        padding: 0;
    }

    .order {
        &__history {
           padding: 10px;
        }

        &__title {
            font-size: 13px;
            font-weight: 300;

            i {
                font-size: 11px;
            }
        }
    
        &__item {
            &-text {
                font-size: 12px;
                width: 40px;
    
                &_name {
                    width: 210px;
                }
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .account {
        &__header_info {
            font-size: 15px;
            margin-bottom: 2px;
        }
    
        &__value {
            font-size: 13px;
        }
    }

    .order {
        &__title {
            font-size: 11px;

            i {
                font-size: 9px;
            }
        }
    
        &__item {
            &-text {
                font-size: 11px;
                width: 30px;
    
                &_name {
                    width: 180px;
                }
            }
        }
    }
}

