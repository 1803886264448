.pay {
    margin-top: 40px;
    margin-bottom: 40px;
    &__title {
        font-size: 27px;
        color: #621D0E;
    }

    &__icon {
        max-width: 200px;
        margin-right: 15px;
    }
}

@media screen and (max-width: 1200px) {
    .pay {
        margin-bottom: 20px;

        &__title {
            font-size: 20px;
        }
    
        &__icon {
            max-width: 120px;
        }
    }
}

@media screen and (max-width: 768px) {
    .pay {
        margin: 0 20px;
    }
}

@media screen and (max-width: 425px) {
    .pay {
        margin-bottom: 20px;
        &__title {
            font-size: 17px;
        }
    
        &__icon {
            max-width: 100px;
        }
    }
}