.smartbanner{
    &-android {
        background: #656565;
        box-shadow: inset 0 4px 0 #621D0E;
    }

    &-android &-button {
        box-shadow: none;
        color: #fff!important;
        
        &-text {
            padding: 5px 15px!important;
            background: transparent!important;
            border: solid 1px #fff
        }
    }

    &-ios &-button {
        box-shadow: none;
        color: #000!important;
        
        &-text {
            padding: 5px 15px!important;
            background: transparent!important;
            border: solid 1px #000
        }
    }
}