.app {
    &__icon {
        max-width: 195px;
        // height: 78px;
    }
    
    &__text {
        font-size: 21px;
        font-weight: 500;
        letter-spacing: 0.06em;
        text-align: center;
        display: block;
        color: #fff;
    }
    
    &__link {
        color: #621D0E;

        &:hover {
            color: #fff;
        }
    }
}

@media screen and (max-width: 1200px) {
    .app {
        &__icon {
            max-width: 155px;
        }
        
        &__text {
            font-size: 17px;
        }
    }
}

@media screen and (max-width: 768px) {
    .app {
        &__icon {
            max-width: 135px;
        }
        
        &__text {
            font-size: 13px;
        }
    }
}

@media screen and (max-width: 425px) {
    .app {
        margin-top: 15px;
    }
}