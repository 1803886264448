.slider-item {
    &__img {
        width: 100%;
    }
}

.ant-carousel .slick-dots li button {
    width: 20px!important;
    height: 20px!important;
    background: #FBC300!important;
    border-radius: 50%!important;
}

.ant-carousel .slick-dots li {
    margin-left: 40px!important;
}

.ant-carousel .slick-dots-bottom {
    bottom: 45px!important;
}

.ant-carousel .slick-dots li.slick-active {
    width: 20px!important;
}

@media screen and (max-width: 768px) {
    .slider-item {
        &__img {
            height: 100%;
        }
    }
}

@media screen and (max-width: 425px) {
    .ant-carousel .slick-dots li button {
        width: 15px!important;
        height: 15px!important;
    }
    
    .ant-carousel .slick-dots li {
        margin-left: 20px!important;
    }
    
    .ant-carousel .slick-dots-bottom {
        bottom: 25px!important;
    }
    
    .ant-carousel .slick-dots li.slick-active {
        width: 15px!important;
    }
}