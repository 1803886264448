.search {
    margin-top: 20px;
    font-size: 15px!important;
    border-radius: 8px!important;
    border: 1px solid #621D0E!important;
    color: #621D0E!important;
    padding: 8px 17px!important;
    height: 33px!important;
}

.menu-list {
    margin-top: 40px;
    flex-direction: column!important;
}

.menu-item {
    margin-bottom: 30px;
    font-size: 18px;

    &__title {
        font-weight: 500;
        color: #621D0E;
        text-transform: uppercase;
        cursor: pointer;
        display: block;
        margin-bottom: 15px;

        &:hover {
            font-weight: 600;
        }

        &_mobile {
            margin-bottom: 5px;
        }
    }

    &__subitem {
        cursor: pointer;
        display: block;
        margin-bottom: 10px;

        &:hover {
            color: #621D0E;
        }

        &_mobile {
            margin-bottom: 0;
        }
    }

    &_mobile {
        margin-left: 15px;
        margin-bottom: 5px;
    }

    &__more {
        color: #621D0E;
        margin-left: 4px;
    }
}

@media screen and (max-width: 1200px) {
    .search {
        margin-top: 10px;
        font-size: 12px!important;
    }
    
    .menu-list {
        margin-top: 20px;
    }
    
    .menu-item {
        font-size: 15px;
    }
}

@media screen and (max-width: 425px) {
    .drawer {
        &__menu {
            top: 259px!important;
        }
    }
    .menu-list {
        margin-bottom: 10px;
    }
}