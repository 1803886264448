.sign {
    display: block;
    padding: 50px 60px;
    box-shadow: 0 3px 8px rgba(0,0,0,0.38);
    margin: 10px 0 40px;

    &_short {
        width: 457px;
    }

    &__form {
        margin-top: 40px;

        &_sms {
            margin-top: 20px;
        }
    }

    &__require {
        font-size: 18px;
        color: #621D0E;
        font-weight: 500;
        margin-left: 3px;
    }

    &__link {
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
        margin-right: 20px;
        color: #575757;
        border-bottom: 2px dashed #575757;
        padding-bottom: 2px;

        &:hover {
            color: #621D0E;
            border-bottom: 2px dashed #621D0E;
        }

        &_active {
            color: #621D0E;
            border-bottom: 1px dashed transparent;
            padding-bottom: 2px;

            &:hover {
                color: #621D0E;
                border-bottom: 2px dashed transparent;
            }
        }
    }

    &__error {
        color: red;
        display: block;
        margin-top: 5px;
        font-size: 12px;
    }

    &__forget {
        display: inline-block;
        font-size: 15px;
        margin-top: 25px;
        color: #621D0E;
        border-bottom: 1px dashed transparent;
        padding-bottom: 2px;

        &:hover {
            color: #621D0E;
            border-bottom: 1px dashed #621D0E;
        }
    }

    &__item {
        margin-bottom: 15px;
    }

    &__label {
        font-size: 18px;
        font-weight: 500;
        color: #000;
    }

    &__input {
        display: block;
        width: 100%;
        border: 0;
        color: #000;
        font-size: 16px;
        border-bottom: 1px solid #575757;
        padding: 3px 0;

        &_phone {
            margin: 10px 0 25px;
        }

        &_sms {
            margin-top: 10px;
            font-size: 20px;
            letter-spacing: 0.2em;
        }

        &::placeholder {
            color: #717171!important;
        }
    }

    &__text {
        margin-top: 15px;
        width: 335px;
    }

    &__checkbox {
        // margin-bottom: 20px!important;
        display: block;
        width: 427px;

        &-text {
            font-size: 16px!important;
            font-weight: 300;
            color: #000;
        }
    }

    &__button {
        width: 100%!important;
        font-weight: 500;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        font-size: 16px;
        cursor: pointer;
        margin-top: 20px;

        &:disabled {
            background-color: #eee!important;
            color: #717171!important;
            cursor: not-allowed!important;
            border-color: #717171!important;
        }
    }
}

@media screen and (max-width: 425px) {
    .sign__checkbox {
        width: 370px;
    }
}