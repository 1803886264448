.location {
    &__icon {
        font-size: 35px;
        color: #621D0E;
        margin-right: 7px;
        margin-top: 5px;
    }

    &__title {
        display: block;
        font-size: 14px;
        font-weight: 500;
        color: #621D0E;
    }

    &__city {
        color: #000;
        text-transform: uppercase;
        font-size: 18px;
        border-bottom: 1px dashed #000;
        line-height: 32px;

        &:hover {
            color: #000;
            border-bottom: 1px dashed transparent;
            text-decoration: none;
        }
    }

    &__contact-text {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.3em;
    }
}

@media screen and (max-width: 1200px) {
    .location {
        &__icon {
            font-size: 30px;
            margin-right: 5px;
            margin-top: 2px;
        }
    
        &__title {
            font-size: 12px;
        }
    
        &__city {
            font-size: 14px;
            line-height: 24px;
        }
    }
}

@media screen and (max-width: 425px) {
    .location {
        margin: 20px 0 0 10px;
    }
}