.app-drawer {
    &__mask {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        position: fixed;
        z-index: 2000;
        overflow: auto;
    }

    &__wrapper {
        background-color: #fff;
        position: fixed;
        z-index: 2001;
        overflow: auto;
        
        animation-duration: 0.5s;
        padding: 20px 15px;
    }

    &__left {
        animation-name: animateLeft;
        top: 0;
        left: 0;
    }

    &__right {
        animation-name: animateRight;
        top: 0;
        right: 0;
    }

    &__top {
        animation-name: animateTop;
        top: 0;
        left: 0;
    }

    &__close {
        color: #621D0E;
        position: absolute;
        top: 15px;
        right: 15px;

        i {
            font-size: 22px;
            cursor: pointer;
        }

        &_mobile {
            position: fixed;
            z-index: 9999;
        }
    }
}

@media screen and (max-width: 768px) {
    .app-drawer {
        &__wrapper {
            padding: 25px 10px;
        }
    }
}

@keyframes animateLeft {
    from { left: -340px; opacity:0; }
    to { left:0; opacity: 1 }
}

@keyframes animateRight {
    from { right: -340px; opacity:0; }
    to { right:0; opacity: 1 }
}

@keyframes animateTop {
    from { top: -340px; opacity:0; }
    to { top:0; opacity: 1 }
}