.header {
    padding: 20px 0;
    box-shadow: 0 3px 6px rgba(0,0,0,0.24);
    background-color: #FCFCFC;
}

.footer {
    background-color: #000;
    padding: 20px 0;

    &__phone_white {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
    }

    &__rights {
        background-color: #621D0E;
        text-align: center;
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.04em;
        padding: 10px 0;
    }
}