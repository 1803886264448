.contact {
    display: block;
    text-align: center;
    
    &__phone {
        font-size: 24px;
        font-weight: 600;
        color: #000;

        &:hover {
            color: #621D0E;
        }
    }

    &__call {
        display: block;
        font-size: 15px;
        text-align: center;
        margin-top: 10px;
        font-weight: 500;
        color: #621D0E;
        width: 217px;
        line-height: 1.4em;

        &_white {
            display: block;
            font-size: 15px;
            text-align: center;
            margin-top: 10px;
            font-weight: 500;
            color: #fff;
            width: 217px;
            line-height: 1.4em;
        }
    }
}

@media screen and (max-width: 1200px) {
    .contact {
        &__phone {
            font-size: 18px;
        }

        &__call {
            font-size: 12px;
            width: 188px;
            line-height: 1.4em;
        }
    }
}

@media screen and (max-width: 425px) {
    .contact {
        margin-right: 10px;
    }
}

@media screen and (max-width: 320px) {
    .contact {
        margin-left: 10px;
        margin-right: 0;
    }
}