.reviews {
    &__header {
        font-size: 20px;
        font-weight: 600;
        color: #621D0E;
    }

    &__text {
        font-size: 15px;
        font-weight: 300;
        line-height: 1.4em;
        margin: 15px 0;

        &_margin {
            margin-bottom: 40px;
        }
    }

    &__card {
        box-shadow: 0 3px 8px rgba(0,0,0,0.38);
        padding: 1px 60px!important;
        margin: 30px 0!important;
        flex-flow: row!important;

        &_padding {
            padding: 35px 60px!important;
        }
    }

    &__button {
        width: 237px;
        margin-bottom: 30px;
    }

    &__item {
        // display: block;
        flex-flow: row!important;
        margin-top: 20px;
    }

    &__title {
        font-size: 20px;
        font-weight: 500;
        color: #621D0E;
        text-transform: none;
    }

    &__logo {
        i {
            font-size: 45px;
            margin-bottom: 5px;
        }

        &-name {
            display: block;
            width: 80px;
        }

        font-size: 16px;
        color: #575757;
        text-align: center;
        margin-right: 20px;
    }

    &__spin {
        top: 9px!important;
    }

    &__input {
        width: 300px;
    }

    &__content {
        &-date {
            font-size: 15px;
            color: #621D0E;
        }

        &-text {
            font-size: 15px;
            margin-top: 10px;
        }
    }

    &__more-btn {
        margin: 30px 0 0 0;
    }
}

@media screen and (max-width: 768px) {
    .reviews {
        padding: 0 15px;
    
        &__text {
            font-size: 15px;
            font-weight: 300;
            line-height: 1.4em;
            margin: 15px 0;
    
            &_margin {
                margin-bottom: 40px;
            }
        }
    
        &__card {
            padding: 1px 30px!important;
            margin: 30px 0!important;
            flex-flow: row!important;
    
            &_padding {
                padding: 25px 30px!important;
            }
        }
    }
}