.bonuspage {
    &__header {
        font-size: 20px;
        font-weight: 600;
        color: #621D0E;
    }

    &__text {
        font-size: 15px;
        font-weight: 300;
        line-height: 1.4em;
        margin: 15px 0;

        &_margin {
            margin-bottom: 40px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .bonuspage {
        &__header {
            font-size: 18px;
            line-height: 1.5em;
        }
    }
}

@media screen and (max-width: 768px) {
    .bonuspage {
        padding: 0 15px;
    }
}

@media screen and (max-width: 425px) {
    
}